import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {userHasValidToken} from "../../../utils/auth";
import {LOGGED_IN_LANDING_ROUTE_OWNER} from "../../../configs/constants";

const PublicOnlyRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={(props) => {
        const hasValidToken = userHasValidToken();
        if (hasValidToken) { // is logged in
            return <Redirect to={LOGGED_IN_LANDING_ROUTE_OWNER}/>;
        } else {
            return <Component {...props} {...rest}/>;
        }
    }}/>
);

export default PublicOnlyRoute;
