import * as constants from "../configs/constants";

export function saveUserData(userData) {
    return {
        type: constants.ACTION_SAVE_USER_DATA,
        data: userData,
    };
}

export function incrementOnboardingStep() {
    return {
        type: constants.ACTION_NEXT_ONBOARDING_STEP
    };
}

export function clearUserData() {
    return {
        type: constants.ACTION_CLEAR_USER_DATA,
    };
}
