import React from "react";
import ReactDOM from "react-dom";

import "./index.scss";
import App from "./App";

import * as serviceWorker from "./serviceWorker";
import store from "./configs/configureStore";
import {Provider} from "react-redux";

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    window.document.getElementById("app")
);

serviceWorker.unregister();
