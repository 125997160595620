import React from "react";
import styles from "./AuthLayout.module.scss";
import GdrtokenLogo from "../assets/images/gdrtoken-logo.png";

function AuthLayout({children, containerClass}) {
    return <div className={styles.wrap}>
        
        <div
            className={
                `${styles.container}${containerClass ? ` ${containerClass}` : ''}`
            }>
            <div className={styles.logo}>
                <img src={GdrtokenLogo} alt="GDRToken logo"/>
            </div>    
            {children}
        </div>
    </div>;
}

export default AuthLayout;
