import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ReactNotification from "react-notifications-component";
import { userHasValidToken } from "./utils/auth";
import OwnerRoute from "./components/generic/RoutesWrapper/OwnerRoute";
import PublicOnlyRoute from "./components/generic/RoutesWrapper/PublicOnlyRoute";
import PageNotFound from "./components/generic/PageNotFound/PageNotFound";
import { useDispatch } from "react-redux";
import * as commonActions from "./actions/commonActions";
import { main_api } from "./utils/axios_helper";
import ServerError from "./components/generic/ServerError/ServerError";
import ErrorBlock from "./components/generic/ErrorBlock/ErrorBlock";
import FullPageLoader from "./components/generic/Loaders/FullPageLoader";

// Employee routes
const Login = lazy(() => import("./pages/login"));
const IndexPage = lazy(() => import("./pages"));
const LogoutPage = lazy(() => import("./pages/logout"));
const SupportPage = lazy(() => import("./pages/support"));
const UserPage = lazy(() => import("./pages/user"));
const SupportDetailsPage = lazy(() => import("./pages/supportdetails"));
const ReferralPage = lazy(() => import("./pages/referral"));
const NftPage = lazy(() => import("./pages/nft"));
const NFTDetailsNew = lazy(() => import("./pages/ntfdetailsnew"));
const NFTDetailsEdit = lazy(() => import("./pages/ntfdetailsedit"));

const ShopPage = lazy(() => import("./pages/shop"));
const ShopNewDetails = lazy(() => import("./pages/shopnews"));
const ShopEditDetail = lazy(() => import("./pages/shopedit"));
const NotificationPage = lazy(() => import("./pages/notification/index.js"));
const NewNotification = lazy(() =>
    import("./pages/notification/newNotification.js")
);

const WithdrawPage = lazy(() => import("./pages/withdraw/index.js"));

const appVersionPage = lazy(() => import("./pages/app_version/index.js"));
const newAppVersionPage = lazy(() =>
    import("./pages/app_version/newVersion.js")
);

function App() {
    const [isLoading, setIsLoading] = useState(true);
    const [apiErrors, setApiErrors] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        if (userHasValidToken()) {
            main_api
                .get("/api/users/status/")
                .then((response) => {
                    dispatch(commonActions.saveUserData(response.data));
                })
                .catch((error) => {
                    setApiErrors(error.response.data);
                })
                .then(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    }, [setIsLoading, setApiErrors, dispatch]);

    if (isLoading) {
        return <FullPageLoader />;
    } else if (Object.keys(apiErrors).length > 0) {
        return (
            <ServerError>
                <ErrorBlock errors={apiErrors.detail} />
            </ServerError>
        );
    }

    return (
        <Router>
            <Suspense fallback={<FullPageLoader />}>
                <Switch>
                    <PublicOnlyRoute path="/login" exact component={Login} />
                    <OwnerRoute path="/" exact component={IndexPage} />
                    <OwnerRoute path="/support" exact component={SupportPage} />
                    <OwnerRoute path="/nft" exact component={NftPage} />

                    <OwnerRoute
                        path="/nft/new"
                        exact
                        component={NFTDetailsNew}
                    />
                    <OwnerRoute
                        path="/notification"
                        exact
                        component={NotificationPage}
                    />
                    <OwnerRoute
                        path="/withdraws"
                        exact
                        component={WithdrawPage}
                    />
                    {/* appVersionPage */}
                    <OwnerRoute
                        path="/appversion"
                        exact
                        component={appVersionPage}
                    />
                    {/* newAppVersionPage */}
                    <OwnerRoute
                        path="/appversion/new"
                        exact
                        component={newAppVersionPage}
                    />
                    <OwnerRoute
                        path="/notification/new"
                        exact
                        component={NewNotification}
                    />
                    <OwnerRoute path="/user/:id" exact component={UserPage} />
                    <OwnerRoute
                        path="/support/:id"
                        exact
                        component={SupportDetailsPage}
                    />
                    <OwnerRoute
                        path="/referral/:id"
                        exact
                        component={ReferralPage}
                    />
                    <OwnerRoute
                        path="/nft/:id"
                        exact
                        component={NFTDetailsEdit}
                    />
                    <OwnerRoute path="/shop" exact component={ShopPage} />
                    <OwnerRoute
                        path="/shop/new"
                        exact
                        component={ShopNewDetails}
                    />
                    <OwnerRoute
                        path="/shop/:id"
                        exact
                        component={ShopEditDetail}
                    />

                    <Route path="/logout" exact component={LogoutPage} />
                    <Route path="*">
                        <PageNotFound />
                    </Route>
                </Switch>
            </Suspense>
        </Router>
    );
}

export default App;
