import React from 'react';
import {Redirect, Route,useLocation } from 'react-router-dom';
import {userHasValidToken} from "../../../utils/auth";
import {useSelector} from "react-redux";
import {LOGGED_IN_LANDING_ROUTE_WORKFORCE} from "../../../configs/constants";

const OwnerRoute = ({component: Component, ...rest}) => {
    const is_superuser = useSelector(
        (store) => store.commonReducer.is_superuser
    );
    const phoneVerified = useSelector(
        (store) => store.commonReducer.phoneVerified
    );
    const onboardingStep = useSelector(
        (store) => store.commonReducer.onboardingStep
    );
    const emailVerified = useSelector(
        (store) => store.commonReducer.emailVerified
    );
    const location = useLocation();      
    const activePlan = useSelector(
        (store) => store.commonReducer.activePlan
    );
    return <Route {...rest} render={(props) => {
        const hasValidToken = userHasValidToken();
        if (hasValidToken) { // is logged in
            if (is_superuser === true) {
                return <Component {...props} {...rest}/>;
            } else { // workforce
                return <Redirect to={LOGGED_IN_LANDING_ROUTE_WORKFORCE}/>;
            }
        } else {
            return <Redirect to='/login'/>;
        }
    }}/>
};

export default OwnerRoute;
