import React from "react";
import AuthLayout from "../../../layouts/AuthLayout";

export default function ServerError({children}) {
    return <AuthLayout>
        <div className={`text-center`}>
            <h2>500 Server Error. Please try again.</h2>
            {children}
        </div>
    </AuthLayout>;
}
