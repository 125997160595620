import * as constants from '../configs/constants';

const initialState = {
    is_superuser: null,
    onboardingStep: null,
    firstName: '',
    middleName: '',
    lastName: '',
    companyLogo: '',
    profilePhoto: '',
    phoneVerified: null,
    firstLogin: null,
};

export default function commonReducer(state = initialState, action) {
    switch (action.type) {
        case constants.ACTION_SAVE_USER_DATA: {
            return {
                ...state,
                ...action.data,
            };
        }
        case constants.ACTION_NEXT_ONBOARDING_STEP: {
            return {
                ...state,
                onboardingStep: state.onboardingStep === (
                    state.is_superuser === 'owner' ? 4 : 3
                ) ? -1 : state.onboardingStep + 1
            };
        }
        case constants.ACTION_CLEAR_USER_DATA: {
            return {
                ...initialState
            };
        }
        default: {
            return state;
        }
    }
}

