import React from "react";
import ReactLoading from "react-loading";
import styles from "./FullPageLoader.module.scss";

export default function FullPageLoader() {
    return <div className={styles.wrap}>
        <ReactLoading
            type={"spin"}
            color="var(--secondary)"
            height={"25px"}
            width={"25px"}
        />
    </div>;
}
