import React from "react";

export default function ErrorBlock({errors}) {
    if (errors && !Array.isArray(errors)) {
        return <ul className={'alert alert-danger list-unstyled'}>
            <li>{errors}</li>
        </ul>;
    } else if (errors && Array.isArray(errors) && errors.length > 0) {
        return <ul className={'alert alert-danger list-unstyled'}>
            {errors.map((item, index) => <li key={index}>{item}</li>)}
        </ul>;
    }
    return null;
}
