import React from "react";
import AuthLayout from "../../../layouts/AuthLayout";

export default function PageNotFound() {
    return <AuthLayout>
        <div className={`text-center`}>
            <h2>Page not found.</h2>
        </div>
    </AuthLayout>;
}
